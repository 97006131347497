// -----------------------------------------------------------------------------
// This file defines classes for showing and hiding elements.
// -----------------------------------------------------------------------------

// Visually show/hide helpers
@mixin visually-hidden() {
	// sass-lint:disable no-important
	position: absolute !important;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

@mixin visually-shown($position: inherit) {
	// sass-lint:disable no-important
	position: $position !important;
	overflow: auto;
	clip: auto;
	width: auto;
	height: auto;
	margin: 0;
}

.visually-hidden {
	@include visually-hidden();
}

.visually-shown {
	@include visually-shown();
}

.hidden {
	display: none;
}

/**
 * Show/hide for form elements
 */
.label-hidden {
	@include visually-hidden();

	// No placeholders, so force show labels
	.no-placeholder & {
		@include visually-shown();
	}
}

/**
 * Show/hide based on whether JS is supported
 */
// Only show when JS is not supported
.no-js:not(html) {
	display: none;

	.no-js & {
		display: block;
	}
}

// Only show when JS is supported
.js {
	.no-js & {
		display: none;
	}
}

/**
 * Overflow settings
 */
.overflow-hidden {
	overflow: hidden;
}

.overflow-auto {
	overflow: auto;
}
