// -----------------------------------------------------------------------------
// This file defines the fonts used within the type styles.
// -----------------------------------------------------------------------------

@font-face {
	font-family: 'FoundersGroteskMono';
	src: url('../fonts/FoundersGroteskMonoWeb-Medium.eot');
	src: url('../fonts/FoundersGroteskMonoWeb-Medium.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/FoundersGroteskMonoWeb-Medium.woff2') format('woff2'),
			 url('../fonts/FoundersGroteskMonoWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FoundersGroteskMono';
	src: url('../fonts/FoundersGroteskMonoWeb-Bold.eot');
	src: url('../fonts/FoundersGroteskMonoWeb-Bold.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/FoundersGroteskMonoWeb-Bold.woff2') format('woff2'),
			 url('../fonts/FoundersGroteskMonoWeb-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

// 1. Non-webfont to emulate FOUT before fonts are loaded
// 2. Use webfont once it’s loaded
// https://www.bramstein.com/writing/web-font-loading-patterns.html

@mixin font-mono {
	font-family: monospace; /* 1 */
	font-style: normal;
	font-weight: 500;

	@at-root .fonts-loaded & {
		font-family: 'FoundersGroteskMono', 'Courier New', monospace; /* 2 */
	}
}
