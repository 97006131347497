// -----------------------------------------------------------------------------
// This file defines the grid and breakpoints used within the design system.
// -----------------------------------------------------------------------------

$grid-medium: 540px;
$grid-large: 990px;
$grid-xlarge: 1290px;
$grid-widescreen: 1800px;

$grid-gutter: 0.3125rem;
$grid-gutter-lg: 0.3125rem;

$small: 'small';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$xlarge: 'xlarge';
$xlarge-down: 'xlarge-down';
$xlarge-up: 'xlarge-up';
$widescreen: 'widescreen';

// The `$breakpoints` list is used to build our media queries.
// You can use these in the media-query mixin.
$breakpoints: (
	$small '(max-width: #{$grid-medium - 1})',
	$medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
	$medium-down '(max-width: #{$grid-large - 1})',
	$medium-up '(min-width: #{$grid-medium})',
	$large '(min-width: #{$grid-large}) and (max-width: #{$grid-xlarge - 1})',
	$large-down '(max-width: #{$grid-xlarge - 1})',
	$large-up '(min-width: #{$grid-large})',
	$xlarge '(min-width: #{$grid-xlarge}) and (max-width: #{$grid-widescreen - 1})',
	$xlarge-down '(max-width: #{$grid-widescreen - 1})',
	$xlarge-up '(min-width: #{$grid-xlarge})',
	$widescreen '(min-width: #{$grid-widescreen})'
);

/*============================================================================
	Generate breakpoint-specific column widths and push classes
		- Default column widths: $breakpoint-has-widths: ($small, $medium-up);
		- Default is no push classes
		- Will not work if `styles/global/grid.scss` is removed
==============================================================================*/
$breakpoint-has-widths: ($medium-down, $large-up, $xlarge-up);
$breakpoint-has-push: ($medium-down, $large-up, $xlarge-up);
