/**
 * Control the line breaks of the list on small screens
 */
.work-category-links__link:nth-of-type(3) {
	clear: left;
}

@media screen and (min-width: 400px) {
	.work-category-links__link:nth-of-type(3) {
		clear: none;
	}
}
