// -----------------------------------------------------------------------------
// This file defines the spacing used within the design system.
// -----------------------------------------------------------------------------

// ----------------------------------------
// Absolute horizontal and vertical spacing
// ----------------------------------------

$spacer-base: 1.875rem; // 30px
$spacer-ratio: 1.333; 	// Perfect fourth

$spacer-none: 0;
$spacer-line-height-sm: 0.8em;
$spacer-line-height: 1.2em;
$spacer-line-height-lg: 2em;

$spacer-sm1: $spacer-base / $spacer-ratio;  // ~22.5px
$spacer-sm2: $spacer-sm1 / $spacer-ratio;  // ~16.9px
$spacer-sm3: $spacer-sm2 / $spacer-ratio;  // ~12.7px
$spacer-sm4: $spacer-sm3 / $spacer-ratio;  // ~9.5px
$spacer-sm5: $spacer-sm4 / $spacer-ratio;  // ~7.2px

$spacer-lg1: $spacer-base * $spacer-ratio; // ~40px
$spacer-lg2: $spacer-lg1 * $spacer-ratio; // ~53.3px
$spacer-lg3: $spacer-lg2 * $spacer-ratio; // ~71.1px
$spacer-lg4: $spacer-lg3 * $spacer-ratio; // ~94.7px
$spacer-lg5: $spacer-lg4 * $spacer-ratio; // ~126.3px
$spacer-lg6: $spacer-lg5 * $spacer-ratio; // ~168.3px
$spacer-lg7: $spacer-lg6 * $spacer-ratio; // ~224.4px

$spacer-sizes: (
	none $spacer-none,
	line-height-sm $spacer-line-height-sm,
	line-height $spacer-line-height,
	line-height-lg $spacer-line-height-lg,
	sm5 $spacer-sm5,
	sm4 $spacer-sm4,
	sm3 $spacer-sm3,
	sm2 $spacer-sm2,
	sm1 $spacer-sm1,
	base $spacer-base,
	lg1 $spacer-lg1,
	lg2 $spacer-lg2,
	lg3 $spacer-lg3,
	lg4 $spacer-lg4,
	lg5 $spacer-lg5,
	lg6 $spacer-lg6,
	lg7 $spacer-lg7
);

// Margin utility classes
@each $name, $value in $spacer-sizes {
	.m-t-spacer-#{$name} {
		margin-top: $value;
	}
	.m-b-spacer-#{$name} {
		margin-bottom: $value;
	}
	.m-l-spacer-#{$name} {
		margin-left: $value;
	}
	.m-r-spacer-#{$name} {
		margin-right: $value;
	}
	.m-x-spacer-#{$name} {
		margin-right: $value;
		margin-left: $value;
	}
	.m-y-spacer-#{$name} {
		margin-top: $value;
		margin-bottom: $value;
	}

	.m-t-minus-#{$name} {
		margin-top: -$value;
	}
	.m-b-minus-#{$name} {
		margin-bottom: -$value;
	}
	.m-l-minus-#{$name} {
		margin-left: -$value;
	}
	.m-r-minus-#{$name} {
		margin-right: -$value;
	}
	.m-x-minus-#{$name} {
		margin-right: -$value;
		margin-left: -$value;
	}
	.m-y-minus-#{$name} {
		margin-top: -$value;
		margin-bottom: -$value;
	}

	.large-up--m-t-spacer-#{$name} {
		@include media-query($large-up) {
			margin-top: $value !important;
		}
	}
	.large-up--m-b-spacer-#{$name} {
		@include media-query($large-up) {
			margin-bottom: $value !important;
		}
	}
	.large-up--m-l-spacer-#{$name} {
		@include media-query($large-up) {
			margin-left: $value !important;
		}
	}
	.large-up--m-r-spacer-#{$name} {
		@include media-query($large-up) {
			margin-right: $value !important;
		}
	}
	.large-up--m-x-spacer-#{$name} {
		@include media-query($large-up) {
			margin-top: $value !important;
			margin-bottom: $value !important;
		}
	}
	.large-up--m-x-spacer-#{$name} {
		@include media-query($large-up) {
			margin-left: $value !important;
			margin-right: $value !important;
		}
	}

	.large-up--m-t-minus-#{$name} {
		@include media-query($large-up) {
			margin-top: -$value !important;
		}
	}
	.large-up--m-b-minus-#{$name} {
		@include media-query($large-up) {
			margin-bottom: -$value !important;
		}
	}
	.large-up--m-l-minus-#{$name} {
		@include media-query($large-up) {
			margin-left: -$value !important;
		}
	}
	.large-up--m-r-minus-#{$name} {
		@include media-query($large-up) {
			margin-right: -$value !important;
		}
	}
	.large-up--m-x-minus-#{$name} {
		@include media-query($large-up) {
			margin-top: -$value !important;
			margin-bottom: -$value !important;
		}
	}
	.large-up--m-x-minus-#{$name} {
		@include media-query($large-up) {
			margin-left: -$value !important;
			margin-right: -$value !important;
		}
	}

	.xlarge-up--m-t-spacer-#{$name} {
		@include media-query($large-up) {
			margin-top: $value !important;
		}
	}
	.xlarge-up--m-b-spacer-#{$name} {
		@include media-query($large-up) {
			margin-bottom: $value !important;
		}
	}
	.xlarge-up--m-l-spacer-#{$name} {
		@include media-query($large-up) {
			margin-left: $value !important;
		}
	}
	.xlarge-up--m-r-spacer-#{$name} {
		@include media-query($large-up) {
			margin-right: $value !important;
		}
	}
	.xlarge-up--m-x-spacer-#{$name} {
		@include media-query($large-up) {
			margin-top: $value !important;
			margin-bottom: $value !important;
		}
	}
	.xlarge-up--m-x-spacer-#{$name} {
		@include media-query($large-up) {
			margin-left: $value !important;
			margin-right: $value !important;
		}
	}

	.xlarge-up--m-t-minus-#{$name} {
		@include media-query($large-up) {
			margin-top: -$value !important;
		}
	}
	.xlarge-up--m-b-minus-#{$name} {
		@include media-query($large-up) {
			margin-bottom: -$value !important;
		}
	}
	.xlarge-up--m-l-minus-#{$name} {
		@include media-query($large-up) {
			margin-left: -$value !important;
		}
	}
	.xlarge-up--m-r-minus-#{$name} {
		@include media-query($large-up) {
			margin-right: -$value !important;
		}
	}
	.xlarge-up--m-x-minus-#{$name} {
		@include media-query($large-up) {
			margin-top: -$value !important;
			margin-bottom: -$value !important;
		}
	}
	.xlarge-up--m-x-minus-#{$name} {
		@include media-query($large-up) {
			margin-left: -$value !important;
			margin-right: -$value !important;
		}
	}
}

// Padding utility classes
@each $name, $value in $spacer-sizes {
	.p-t-spacer-#{$name} {
		padding-top: $value;
	}
	.p-b-spacer-#{$name} {
		padding-bottom: $value;
	}
	.p-l-spacer-#{$name} {
		padding-left: $value;
	}
	.p-r-spacer-#{$name} {
		padding-right: $value;
	}
	.p-x-spacer-#{$name} {
		padding-right: $value;
		padding-left: $value;
	}
	.p-y-spacer-#{$name} {
		padding-top: $value;
		padding-bottom: $value;
	}

	.large-up--p-t-spacer-#{$name} {
		@include media-query($large-up) {
			padding-top: $value !important;
		}
	}
	.large-up--p-b-spacer-#{$name} {
		@include media-query($large-up) {
			padding-bottom: $value !important;
		}
	}
	.large-up--p-l-spacer-#{$name} {
		@include media-query($large-up) {
			padding-left: $value !important;
		}
	}
	.large-up--p-r-spacer-#{$name} {
		@include media-query($large-up) {
			padding-right: $value !important;
		}
	}
	.large-up--p-x-spacer-#{$name} {
		@include media-query($large-up) {
			padding-top: $value !important;
			padding-bottom: $value !important;
		}
	}
	.large-up--p-x-spacer-#{$name} {
		@include media-query($large-up) {
			padding-left: $value !important;
			padding-right: $value !important;
		}
	}
}

// Spacer mixins
// usage: @include m-t-spacer-($spacer-lg1);
@mixin m-t-spacer-($value) {
	margin-top: $value;
}
@mixin m-b-spacer-($value) {
	margin-bottom: $value;
}
@mixin m-l-spacer-($value) {
	margin-left: $value;
}
@mixin m-r-spacer-($value) {
	margin-right: $value;
}
@mixin m-x-spacer-($value) {
	margin-left: $value;
	margin-right: $value;
}
@mixin m-y-spacer-($value) {
	margin-top: $value;
	margin-bottom: $value;
}

@mixin m-t-minus-($value) {
	margin-top: -$value;
}
@mixin m-b-minus-($value) {
	margin-bottom: -$value;
}
@mixin m-l-minus-($value) {
	margin-left: -$value;
}
@mixin m-r-minus-($value) {
	margin-right: -$value;
}
@mixin m-x-minus-($value) {
	margin-left: -$value;
	margin-right: -$value;
}
@mixin m-y-minus-($value) {
	margin-top: -$value;
	margin-bottom: -$value;
}

@mixin p-t-spacer-($value) {
	padding-top: $value;
}
@mixin p-b-spacer-($value) {
	padding-bottom: $value;
}
@mixin p-l-spacer-($value) {
	padding-left: $value;
}
@mixin p-r-spacer-($value) {
	padding-right: $value;
}
@mixin p-x-spacer-($value) {
	padding-left: $value;
	padding-right: $value;
}
@mixin p-y-spacer-($value) {
	padding-top: $value;
	padding-bottom: $value;
}
