/**
 * Position video poster image
 */
.video-embed {
	position: relative;
  overflow: hidden;
}

.video-embed__poster {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -1;
}

/**
 * Prevent iframes taking over pointer events
 * We can still interact with videos because we use Plyr
 */
.video-embed iframe {
	pointer-events: none;
}

/**
 * Position div to block click events on mobile as pointer-events doesn’t work
 */
.video-embed__block {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 5;
}
