// -----------------------------------------------------------------------------
// This file sets the base styles using the design system.
// -----------------------------------------------------------------------------

/**
 * Base site colors
 */
body {
	color: $col--type-body;
}

/* Highlight color only visible when page overscroll occurs */
html {
	background-color: $col--background-html;
}

/* Main site background-color */
.site-wrapper {
	background-color: transparent;
}

.content-wrapper {
	background-color: $col--background-site-wrapper;
}

/**
 * Prevent horizontal scroll
 */
body {
	overflow-x: hidden;
}

/**
 * Base site typography
 */
body {
	@include type-mono;
	@include font-size-($font-size-base);
	hanging-punctuation: first;
}

/**
 * Set spacing for typographic elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
table {
	margin: $spacer-line-height 0; // use base line-height
}

/**
 * Prevent bold text from being double bolded
 */
strong,
b {
	font-weight: inherit;
}

/**
 * Prevent italic text from being double italicised
 */
em,
i {
	font-style: normal;
}

/**
 * User selected text
 */
::selection {
	background: $col--selection-bg;
	color: $col--selection-text;
}

/**
 * Change base font-size for small and large screens
 * This makes all elements styled with rems increase proportionally
 * 1. Standard, 16px base. Body text is set to 14px from here.
 * 2. This increases our body text style to 18px instead.
 */
html {
 font-size: 100%; /* 1. */
}

@include media-query($widescreen) {
	html {
		font-size: 128.571429%; /* 2. */
	}
}

/**
 * Make the browser scroll smoothly to anchor links
 */
html {
	scroll-behavior: smooth;
}

/**
 * Offset anchor links
 */
:target::before {
	content: '';
	display: block;
	height: $spacer-lg3; /* offset height*/
	margin-top: -$spacer-lg3; /* negative offset height */
}

/* Disable for anchors in lists */
ol :target::before,
ul :target::before {
	content: '';
	display: block;
	height: 0; /* offset height*/
	margin-top: $spacer-none; /* negative offset height */
}

/**
 * List formatting
 */
ol,
ul {
	padding-left: $spacer-line-height;
}
