/*============================================================================
	Grid
		- Based on CSS Wizardry grid
==============================================================================*/

.grid {
  @include clearfix();
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -$grid-gutter;
}

.grid__item {
  float: left;
  padding-left: $grid-gutter;
  width: 100%;

  &[class*='--push'] {
    position: relative;
  }
}

@include media-query($medium-up) {

	.grid {
		margin-left: -$grid-gutter-lg;
	}

	.grid__item {
		padding-left: $grid-gutter-lg;
	}
}

/*============================================================================
	Reversed grids allow you to structure your source in the opposite
	order to how your rendered layout will appear.
==============================================================================*/
.grid--rev {
	direction: rtl;
	text-align: left;

	> .grid__item {
		direction: ltr;
		text-align: left;
		float: right;
	}
}

/*============================================================================
	Grid Columns
		- Create width classes, prepended by the breakpoint name.
==============================================================================*/
// sass-lint:disable brace-style empty-line-between-blocks
@mixin grid-column-generator($breakpoint: '') {
	/** Whole */
	.#{$breakpoint}one-whole { width: 100%; }

	/* Halves */
	.#{$breakpoint}one-half { width: percentage(1 / 2); }

	/* Thirds */
	.#{$breakpoint}one-third { width: percentage(1 / 3); }
	.#{$breakpoint}two-thirds { width: percentage(2 / 3); }

	/* Quarters */
	.#{$breakpoint}one-quarter { width: percentage(1 / 4); }
	.#{$breakpoint}two-quarters { width: percentage(2 / 4); }
	.#{$breakpoint}three-quarters { width: percentage(3 / 4); }

	/* Fifths */
	.#{$breakpoint}one-fifth { width: percentage(1 / 5); }
	.#{$breakpoint}two-fifths { width: percentage(2 / 5); }
	.#{$breakpoint}three-fifths { width: percentage(3 / 5); }
	.#{$breakpoint}four-fifths { width: percentage(4 / 5); }

	/* Sixths */
	.#{$breakpoint}one-sixth { width: percentage(1 / 6); }
	.#{$breakpoint}two-sixths { width: percentage(2 / 6); }
	.#{$breakpoint}three-sixths { width: percentage(3 / 6); }
	.#{$breakpoint}four-sixths { width: percentage(4 / 6); }
	.#{$breakpoint}five-sixths { width: percentage(5 / 6); }

	/* Sevenths */
	.#{$breakpoint}one-seventh { width: percentage(1 / 7); }
	.#{$breakpoint}two-sevenths { width: percentage(2 / 7); }
	.#{$breakpoint}three-sevenths { width: percentage(3 / 7); }
	.#{$breakpoint}four-sevenths { width: percentage(4 / 7); }
	.#{$breakpoint}five-sevenths { width: percentage(5 / 7); }
	.#{$breakpoint}six-sevenths { width: percentage(6 / 7); }

	/* Eighths */
	.#{$breakpoint}one-eighth { width: percentage(1 / 8); }
	.#{$breakpoint}two-eighths { width: percentage(2 / 8); }
	.#{$breakpoint}three-eighths { width: percentage(3 / 8); }
	.#{$breakpoint}four-eighths { width: percentage(4 / 8); }
	.#{$breakpoint}five-eighths { width: percentage(5 / 8); }
	.#{$breakpoint}six-eighths { width: percentage(6 / 8); }
	.#{$breakpoint}seven-eighths { width: percentage(7 / 8); }

	/* Ninths */
	.#{$breakpoint}one-ninth { width: percentage(1 / 9); }
	.#{$breakpoint}two-ninths { width: percentage(2 / 9); }
	.#{$breakpoint}three-ninths { width: percentage(3 / 9); }
	.#{$breakpoint}four-ninths { width: percentage(4 / 9); }
	.#{$breakpoint}five-ninths { width: percentage(5 / 9); }
	.#{$breakpoint}six-ninths { width: percentage(6 / 9); }
	.#{$breakpoint}seven-ninths { width: percentage(7 / 9); }
	.#{$breakpoint}eight-ninths { width: percentage(8 / 9); }

	/* Tenths */
	.#{$breakpoint}one-tenth { width: percentage(1 / 10); }
	.#{$breakpoint}two-tenths { width: percentage(2 / 10); }
	.#{$breakpoint}three-tenths { width: percentage(3 / 10); }
	.#{$breakpoint}four-tenths { width: percentage(4 / 10); }
	.#{$breakpoint}five-tenths { width: percentage(5 / 10); }
	.#{$breakpoint}six-tenths { width: percentage(6 / 10); }
	.#{$breakpoint}seven-tenths { width: percentage(7 / 10); }
	.#{$breakpoint}eight-tenths { width: percentage(8 / 10); }
	.#{$breakpoint}nine-tenths { width: percentage(9 / 10); }

	/* Twelfths */
	.#{$breakpoint}one-twelfth { width: percentage(1 / 12); }
	.#{$breakpoint}two-twelfths { width: percentage(2 / 12); }
	.#{$breakpoint}three-twelfths { width: percentage(3 / 12); }
	.#{$breakpoint}four-twelfths { width: percentage(4 / 12); }
	.#{$breakpoint}five-twelfths { width: percentage(5 / 12); }
	.#{$breakpoint}six-twelfths { width: percentage(6 / 12); }
	.#{$breakpoint}seven-twelfths { width: percentage(7 / 12); }
	.#{$breakpoint}eight-twelfths { width: percentage(8 / 12); }
	.#{$breakpoint}nine-twelfths { width: percentage(9 / 12); }
	.#{$breakpoint}ten-twelfths { width: percentage(10 / 12); }
	.#{$breakpoint}eleven-twelfths { width: percentage(11 / 12); }
}

/*================ Grid push classes ================*/
@mixin grid-push-generator($breakpoint: '') {
	/* Halves */
	.#{$breakpoint}push-one-half { left: percentage(1 / 2); }

	/* Thirds */
	.#{$breakpoint}push-one-third { left: percentage(1 / 3); }
	.#{$breakpoint}push-two-thirds { left: percentage(2 / 3); }

	/* Quarters */
	.#{$breakpoint}push-one-quarter { left: percentage(1 / 4); }
	.#{$breakpoint}push-two-quarters { left: percentage(2 / 4); }
	.#{$breakpoint}push-three-quarters { left: percentage(3 / 4); }

	/* Fifths */
	.#{$breakpoint}push-one-fifth { left: percentage(1 / 5); }
	.#{$breakpoint}push-two-fifths { left: percentage(2 / 5); }
	.#{$breakpoint}push-three-fifths { left: percentage(3 / 5); }
	.#{$breakpoint}push-four-fifths { left: percentage(4 / 5); }

	/* Sixths */
	.#{$breakpoint}push-one-sixth { left: percentage(1 / 6); }
	.#{$breakpoint}push-two-sixths { left: percentage(2 / 6); }
	.#{$breakpoint}push-three-sixths { left: percentage(3 / 6); }
	.#{$breakpoint}push-four-sixths { left: percentage(4 / 6); }
	.#{$breakpoint}push-five-sixths { left: percentage(5 / 6); }

	/* Sevenths */
	.#{$breakpoint}push-one-seventh { left: percentage(1 / 7); }
	.#{$breakpoint}push-two-sevenths { left: percentage(2 / 7); }
	.#{$breakpoint}push-three-sevenths { left: percentage(3 / 7); }
	.#{$breakpoint}push-four-sevenths { left: percentage(4 / 7); }
	.#{$breakpoint}push-five-sevenths { left: percentage(5 / 7); }
	.#{$breakpoint}push-six-sevenths { left: percentage(6 / 7); }

	/* Eighths */
	.#{$breakpoint}push-one-eighth { left: percentage(1 / 8); }
	.#{$breakpoint}push-two-eighths { left: percentage(2 / 8); }
	.#{$breakpoint}push-three-eighths { left: percentage(3 / 8); }
	.#{$breakpoint}push-four-eighths { left: percentage(4 / 8); }
	.#{$breakpoint}push-five-eighths { left: percentage(5 / 8); }
	.#{$breakpoint}push-six-eighths { left: percentage(6 / 8); }
	.#{$breakpoint}push-seven-eighths { left: percentage(7 / 8); }

	/* Ninths */
	.#{$breakpoint}push-one-ninth { left: percentage(1 / 9); }
	.#{$breakpoint}push-two-ninths { left: percentage(2 / 9); }
	.#{$breakpoint}push-three-ninths { left: percentage(3 / 9); }
	.#{$breakpoint}push-four-ninths { left: percentage(4 / 9); }
	.#{$breakpoint}push-five-ninths { left: percentage(5 / 9); }
	.#{$breakpoint}push-six-ninths { left: percentage(6 / 9); }
	.#{$breakpoint}push-seven-ninths { left: percentage(7 / 9); }
	.#{$breakpoint}push-eight-ninths { left: percentage(8 / 9); }

	/* Tenths */
	.#{$breakpoint}push-one-tenth { left: percentage(1 / 10); }
	.#{$breakpoint}push-two-tenths { left: percentage(2 / 10); }
	.#{$breakpoint}push-three-tenths { left: percentage(3 / 10); }
	.#{$breakpoint}push-four-tenths { left: percentage(4 / 10); }
	.#{$breakpoint}push-five-tenths { left: percentage(5 / 10); }
	.#{$breakpoint}push-six-tenths { left: percentage(6 / 10); }
	.#{$breakpoint}push-seven-tenths { left: percentage(7 / 10); }
	.#{$breakpoint}push-eight-tenths { left: percentage(8 / 10); }
	.#{$breakpoint}push-nine-tenths { left: percentage(9 / 10); }

	/* Twelfths */
	.#{$breakpoint}push-one-twelfth { left: percentage(1 / 12); }
	.#{$breakpoint}push-two-twelfths { left: percentage(2 / 12); }
	.#{$breakpoint}push-three-twelfths { left: percentage(3 / 12); }
	.#{$breakpoint}push-four-twelfths { left: percentage(4 / 12); }
	.#{$breakpoint}push-five-twelfths { left: percentage(5 / 12); }
	.#{$breakpoint}push-six-twelfths { left: percentage(6 / 12); }
	.#{$breakpoint}push-seven-twelfths { left: percentage(7 / 12); }
	.#{$breakpoint}push-eight-twelfths { left: percentage(8 / 12); }
	.#{$breakpoint}push-nine-twelfths { left: percentage(9 / 12); }
	.#{$breakpoint}push-ten-twelfths { left: percentage(10 / 12); }
	.#{$breakpoint}push-eleven-twelfths { left: percentage(11 / 12); }
}

/*================ Clearfix helper on uniform grids ================*/
@mixin grid-uniform-clearfix($breakpoint: '') {
	.grid--uniform {
		.#{$breakpoint}one-half:nth-child(2n+1),
		.#{$breakpoint}one-third:nth-child(3n+1),
		.#{$breakpoint}one-quarter:nth-child(4n+1),
		.#{$breakpoint}one-fifth:nth-child(5n+1),
		.#{$breakpoint}one-sixth:nth-child(6n+1),
		.#{$breakpoint}two-sixths:nth-child(3n+1),
		.#{$breakpoint}three-sixths:nth-child(2n+1),
		.#{$breakpoint}two-sevenths:nth-child(3n+1),
		.#{$breakpoint}three-sevenths:nth-child(2n+1),
		.#{$breakpoint}one-eighth:nth-child(8n+1),
		.#{$breakpoint}two-eighths:nth-child(4n+1),
		.#{$breakpoint}four-eighths:nth-child(2n+1),
		.#{$breakpoint}five-tenths:nth-child(2n+1),
		.#{$breakpoint}one-twelfth:nth-child(12n+1),
		.#{$breakpoint}two-twelfths:nth-child(6n+1),
		.#{$breakpoint}three-twelfths:nth-child(4n+1),
		.#{$breakpoint}four-twelfths:nth-child(3n+1),
		.#{$breakpoint}six-twelfths:nth-child(2n+1) { clear: both; }
	}
}
// sass-lint:enable brace-style empty-line-between-blocks

/*================ Build Base Grid Classes ================*/
@include grid-column-generator();
@include responsive-display-helper();
@include responsive-text-align-helper();

/*================ Build Responsive Grid Classes ================*/
@each $breakpoint in $breakpoint-has-widths {
	@include media-query($breakpoint) {
		@include grid-column-generator('#{$breakpoint}--');
		@include grid-uniform-clearfix('#{$breakpoint}--');
		@include responsive-display-helper('#{$breakpoint}--');
		@include responsive-text-align-helper('#{$breakpoint}--');
	}
}

/*================ Build Grid Push Classes ================*/
@each $breakpoint in $breakpoint-has-push {
	@include media-query($breakpoint) {
		@include grid-push-generator('#{$breakpoint}--');
	}
}
