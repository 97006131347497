/**
 * Position background image wrapper
 */
.background-image {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	overflow: hidden;
}

.background-image__inner-wrapper {
	width: 100%;
	height: 100%;
	z-index: -1;
	transform: scale(1.1);
}

/**
 * Ensure the content has a z-index and sits in front of background
 */
.parallax-scene {
  z-index: 2;
}

/**
 * Format background settings
 */
.background-image__image {
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	width: 100%;
	height: 100%;
	z-index: -1;
}

/**
 * Dim background image
 */
.background-image__image {
	opacity: 0.8;
  filter: blur(4px);
}

.background-image {
	background-color: $col--background-site-wrapper;
}
