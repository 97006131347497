/*================ Transitions ================*/

/**
 * Define initial load transition
 */
.new-session .site-inner-wrapper {
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	animation-duration: 0.5s;
}

/**
 * Define between pages transition
 */
.fade-in .content-wrapper {
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	animation-duration: 0.5s;
}

.fade-out .content-wrapper {
	animation-name: fadeOut !important;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	animation-duration: 0.5s;

	.background-image {
		// opacity: 0;
	}
}
