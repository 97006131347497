// -----------------------------------------------------------------------------
// This file defines helper classes for positioning and displaying elements.
// -----------------------------------------------------------------------------

// Position
$positions: relative, absolute, fixed;

@each $value in $positions {
	.position-#{$value} {
		position: $value;
	}

	.large-up--position-#{$value} {
		@include media-query($large-up) {
			position: $value;
		}
	}
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

.bottom-0 {
	bottom: 0;
}

.left-0 {
	left: 0;
}

// Display
$displays: inline, inline-block, block;

@each $value in $displays {
	.#{$value} {
		display: $value;
	}

	.large-up--#{$value} {
		@include media-query($large-up) {
			display: $value;
		}
	}
}

// Block centering
// Parents must be relatively positioned
.center-x {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.center-y {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.center-xy {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Text alignment
$alignments: left, center, right;

@each $value in $alignments {
	.align-#{$value} {
		text-align: $value;
	}

	.large-up--align-#{$value} {
		@include media-query($large-up) {
			text-align: $value !important;
		}
	}
}
