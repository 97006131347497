// Clearfix to ensure parents clear their floated children
@mixin clearfix() {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
	// sass-lint:disable
	*zoom: 1;
}

// Media query mixin
@mixin media-query($media-query) {
	$breakpoint-found: false;

	@each $breakpoint in $breakpoints {
		$name: nth($breakpoint, 1);
		$declaration: nth($breakpoint, 2);

		@if $media-query == $name and $declaration {
			$breakpoint-found: true;

			@media only screen and #{$declaration} {
				@content;
			}
		}
	}

	@if $breakpoint-found == false {
		@warn 'Breakpoint "#{$media-query}" does not exist';
	}
}

// Responsive show/hide helper
@mixin responsive-display-helper($breakpoint: '') {
	// sass-lint:disable no-important
	.#{$breakpoint}show {
		display: block !important;
	}

	.#{$breakpoint}hide {
		display: none !important;
	}
}

// Responsive text alignment helper
@mixin responsive-text-align-helper($breakpoint: '') {
	// sass-lint:disable no-important
	.#{$breakpoint}text-left {
		text-align: left !important;
	}

	.#{$breakpoint}text-right {
		text-align: right !important;
	}

	.#{$breakpoint}text-center {
		text-align: center !important;
	}
}
