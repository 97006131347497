// Set vh based on window.innerHeight
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#article-header-id-1
:root {
	--vh: 1vh;
}

.project {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	height: calc(var(--vh) * 100);
	width: 100%;
}

/**
 * Position the gallery
 */
.project-gallery {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.project-gallery__inner-wrapper {
	width: 100%;
	height: 100%;
	transform: scale(1.05);
}

.project-gallery .parallax-layer {
	height: 100%;
}

.swiper-container {
	position: relative;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: calc((var(--vh) * 100) - 12.5rem);
	width: 100vw;
}

/**
 * Format each slide
 */
.swiper-slide__asset-wrapper {
	height: calc((var(--vh) * 100) - 12.5rem);
	width: auto;
	position: relative;
}

.swiper-slide__asset {
	height: 100%;
	width: 100%;
	position: relative;
}

.swiper-slide__asset--image > img,
.swiper-slide__asset--video > .video-embed,
.swiper-slide__asset--video > .plyr {
	width: auto;
	max-height: 100%;
	max-width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
}

.swiper-slide__asset--video,
.ratio-landscape {

	.video-embed,
	.plyr {
		width: 75vw;
	}

	@media (min-aspect-ratio: 16/9) {
		.video-embed,
		.plyr {
			width: 30vw;
		}
	}
}

.ratio-square {

	.video-embed,
	.plyr {
		width: 45vw;
	}

	@media (min-aspect-ratio: 16/9) {
		.video-embed,
		.plyr {
			width: 30vw;
		}
	}
}

.ratio-portrait {

	.video-embed,
	.plyr {
		width: 40vw;
	}

	@media (min-aspect-ratio: 16/9) {
		.video-embed,
		.plyr {
			width: 30vw;
		}
	}
}

/**
 * Dim inactive slides
 */
// .swiper-slide__asset {
// 	background-color: $col--background-site-wrapper;
// }
//
// .swiper-slide .swiper-slide__asset .plyr,
// .swiper-slide .swiper-slide__asset .video-embed,
// .swiper-slide .swiper-slide__asset img {
// 	@include transition--(opacity);
// 	opacity: 0.5;
// }
//
// .swiper-slide-active .swiper-slide__asset .plyr,
// .swiper-slide-active .swiper-slide__asset .video-embed,
// .swiper-slide-active .swiper-slide__asset img {
// 	opacity: 1;
// }

/**
 * Position the pagination
 */
.swiper-pagination {
	position: absolute;
	bottom: 0;
	right: 0;
	left: auto;
	z-index: 10;
	width: 30%;
	padding: $spacer-sm1;
}

/**
 * Format the pagination
 */
.swiper-pagination {
	text-align: right;
}

.swiper-pagination-current {
	margin-right: -0.5em;
}

.swiper-pagination-total {
	margin-left: -0.5em;
}

/**
 * Position the wrapper
 */
.project-info {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;
	width: calc(70% + #{$spacer-sm1});
	padding: $spacer-sm1;
}

/**
 * Format the swiper navigation
 */
.swiper-button-next {
	background-image: url('../img/arrow-right.svg');
	right: $spacer-sm1;
}

.swiper-button-prev {
	background-image: url('../img/arrow-left.svg');
	left: $spacer-sm1;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
	outline: none;
}
