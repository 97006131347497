/*================ Forms ================*/

/**
 * Hide default radio buttons accessibly
 */
.hide-radio {
	opacity: 0;
	position: absolute;
}

/**
 * Show outline when user tabs to focus
 */
html.user-is-tabbing input[type=radio]:focus + label {
	outline: 2px solid #7AACFE !important; /* for non-webkit browsers */
	outline: 5px auto -webkit-focus-ring-color !important;
}

/**
 * Style checked links
 */
input[type=radio]:checked + label > .faux-link {
	@extend .faux-link.active;
}
