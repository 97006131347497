// -----------------------------------------------------------------------------
// This file defines the type styles used within the design system.
// -----------------------------------------------------------------------------

@mixin type-mono {
	@include font-mono;
	@include line-height-($line-height-base);
}

.type-mono {
	@include type-mono;
}

.type-bold {
	font-weight: 700;
}

// Text transform
.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.titlecase {
	text-transform: lowercase;
	display: block;

	&::first-letter {
		text-transform: uppercase;
	}
}
