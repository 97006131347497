/*================ Layout ================*/

/**
 * Min height
 */
.site-inner-wrapper {
	min-height: 100vh;
}

/**
 * Site wrapper
 * 1. Set outer padding
 * 2. Set maximum width and center content
 */
@mixin site-padding {
	padding: $spacer-sm1 $spacer-sm1 $spacer-lg4; /* 1 */
	margin: 0 auto; /* 2 */

	@include media-query($medium-up) {
		max-width: 31.25rem; /* 2 */
	}

	@include media-query($large-up) {
		padding: $spacer-sm1 $spacer-sm1 $spacer-lg4; /* 1 */
		max-width: none; /* 2 */
	}

	@include media-query($large-up) {
		margin: 0 auto; /* 2 */
		max-width: $grid-xlarge; /* 2 */
	}
}

// Full width
@mixin full-width {
	@include m-x-spacer-(-$spacer-sm4);
	width: calc(100% + #{$spacer-sm4} + #{$spacer-sm4});

	@include media-query($medium-up) {
		@include m-x-spacer-($spacer-none);
		width: 100%;
	}

	@include media-query($large-up) {
		@include m-x-spacer-(-$spacer-sm4);
		width: calc(100% + #{$spacer-sm4} + #{$spacer-sm4});
	}

	@include media-query($xlarge-up) {
		@include m-x-spacer-($spacer-none);
		width: 100%;
	}
}

.full-width {
	@include full-width;
}

/**
 * Site header positioning
 */
.site-inner-wrapper {
	@include site-padding;
	position: relative;
}

.site-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
}

.site-header__inner-wrapper {
	@include site-padding;
	padding-bottom: 0 !important;
}

.page-content:not(.page-content--work-entry) {
	@include m-t-spacer-($spacer-lg5);

	@include media-query($large-up) {
		@include m-t-spacer-($spacer-lg7);
	}
}

/**
 * Prevent scrolling on work gallery page
 */
.work {
	overflow: hidden;
}
