/**
 * Make the homepage grid full-width
 */
.home .site-inner-wrapper {
	margin: 0 auto;
	padding-right: 0;
	padding-left: 0;
	width: 100%;
	max-width: none;
}

/**
 * Format text for mobile layout
 */
.homepage-text--small {
	@include p-x-spacer-($spacer-sm1);
	@include p-y-spacer-($spacer-sm4);
	margin: 0 auto;

	@include media-query($medium-up) {
		max-width: 31.25rem;
	}
}

.homepage-text--small {
	font-size: 8vw;

	@include media-query($medium-up) {
		@include font-size-($font-size-lg3);
	}
}

/**
 * Format text for large layout
 */
.homepage-text--large {
	font-size: 3.25vw;
}
