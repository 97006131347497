/*================ Links ================*/

/**
 * Normal links with underlines
 */
a,
a:link,
a:visited,
.faux-link {
	color: $col--link;
	text-decoration: underline;
	text-decoration-color: $col--link-underline;
	@include transition--(text-decoration-color);
}

a:hover,
a:active,
a:focus,
.faux-link:hover,
a:hover .faux-link {
	color: $col--link-hover;
	text-decoration: underline;
	text-decoration-color: $col--link-underline-hover;
	cursor: pointer;
}

a.active,
.faux-link.active {
	color: $col--link-active;
	text-decoration: underline;
	text-decoration-color: $col--link-underline-active;
}

/**
 * Add click effect
 */
a {
	position: relative;
	top: 0;

	&:active {
		top: 0.0625rem;
	}
}

/**
 * Remove link formatting
 */
.link-no-format {
	&,
	&:link,
	&:visited,
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

.link-no-click-effect {
	position: static;
	top: auto;
}

/**
 * Customise mobile tap highlight color
 */
a,
div {
	-webkit-tap-highlight-color: $col--link-tap-highlight;
}
