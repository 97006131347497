/*================ Plyr video skin ================*/

/**
 * Large play button color
 */
.plyr__control--overlaid {
	background: $col--plyr-play;
}

/**
 * Hide large play button on mobile to avoid duplicate play buttons
 */
@media screen and (max-width: 500px) {
	.touchevents .plyr__control--overlaid {
		display: none !important;
	}
}

/**
 * Controls hover state color
 */
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: $col--plyr-hover;
}

/**
 * Progress bar color
 */
.plyr--full-ui input[type=range] {
	color: $col--plyr-progress;
}

/**
 * Use site-wide typeface
 */
.plyr {
	@include type-mono;
}

/**
 * Remove black background
 */
.plyr--video,
.plyr__video-wrapper,
.plyr__poster {
	background-color: transparent;
}
