// -----------------------------------------------------------------------------
// This file defines the line heights used within the design system.
// -----------------------------------------------------------------------------

$line-height-sm: 0.8;
$line-height-base: 1.2;
$line-height-lg: 2;

$line-height-sizes: (
	sm $line-height-sm,
	base $line-height-base,
	lg $line-height-lg
);

// Line-height utility classes
@each $name, $value in $line-height-sizes {
	.line-height-#{$name} {
		line-height: $value !important;
	}

	.large-up--line-height-#{$name} {
		@include media-query($large-up) {
			line-height: $value !important;
		}
	}
}

// Line-height mixins
// usage: @include line-height-($line-height-sm);
@mixin line-height-($value) {
	line-height: $value;
}
