/**
 * Set width of header elements
 */
.site-header__logo {
	width: 40%;
}

.site-header__nav {
	width: 60%;
}

@include media-query($large-up) {
	.site-header__logo,
	.site-header__nav {
		width: 50%;
	}
}
