// -----------------------------------------------------------------------------
// This file defines classes for floated elements.
// -----------------------------------------------------------------------------

.clearfix {
	@include clearfix();
}

// Floats
$floats: left, right, both;

@each $value in $floats {
	.float-#{$value} {
		float: $value;
	}

	.large-up--float-#{$value} {
		@include media-query($large-up) {
			float: $value;
		}
	}
}

// Clear floats
@each $value in $floats {
	.clear-#{$value} {
		clear: $value;
	}

	.large-up--clear-#{$value} {
		@include media-query($large-up) {
			clear: $value;
		}
	}
}
