// -----------------------------------------------------------------------------
// Add Dos ✌️ image for site credit.
// -----------------------------------------------------------------------------

.dos-credit::after {
	content: '';
	display: inline-block;
	background-image: url('../img/dos.svg');
	background-size: contain;
	background-repeat: no-repeat;
	height: 1.5rem;
	width: 1.5rem;
	position: absolute;
	top: -0.1875rem;
	right: -1.625rem;

	@include media-query($large-up) {
		height: 2rem;
		width: 2rem;
		top: 0;
		right: -2.125rem;
	}
}
