// Handle tabs gracefully as per https://hackernoon.com/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2

/* No focus for non tabbers */
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none;
}

/* a subtle focus style for keyboard-input elements */
.text-input:focus {
	outline: 1px solid #aaa; /* Adjust to suit your tastes */
}

/* no outline for non-keyboard-inputs elements */
button:focus,
select:focus {
	outline: none;
}

/* add focus for keyboard users */
html.user-is-tabbing *:focus {
	outline: 2px solid #7AACFE !important; /* for non-webkit browsers */
	outline: 5px auto -webkit-focus-ring-color !important;
}
