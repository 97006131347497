/**
 * Positioning
 */
.svg-loader {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
}

.svg-loader__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**
 * Ensure the loader sits behind the content
 */
.barba-container {
	position: relative;
	z-index: 1;
}

/**
 * Sizing
 */
.svg-loader__icon {
	height: 2rem;
	width: 2rem;
}
