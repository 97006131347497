// -----------------------------------------------------------------------------
// This file defines the font sizes used within the design system.
// -----------------------------------------------------------------------------

$font-size-sm2: 0.75rem; // 12px
$font-size-sm1: 0.9375rem; // 15px
$font-size-base: 1.125rem; // 18px
$font-size-lg1: 1.3125rem; // 21px
$font-size-lg2: 1.75rem; // 28px
$font-size-lg3: 2.625rem; // 42px
$font-size-lg4: 3.75rem; // 60px

$font-sizes: (
	sm2 $font-size-sm2,
	sm1 $font-size-sm1,
	base $font-size-base,
	lg1 $font-size-lg1,
	lg2 $font-size-lg2,
	lg3 $font-size-lg3,
	lg4 $font-size-lg4
);

// Font-size uility classes
@each $name, $value in $font-sizes {
	.font-size-#{$name} {
		font-size: $value;
	}

	.medium-up--font-size-#{$name} {
		@include media-query($medium-up) {
			font-size: $value !important;
		}
	}

	.large-up--font-size-#{$name} {
		@include media-query($large-up) {
			font-size: $value !important;
		}
	}

	.xlarge-up--font-size-#{$name} {
		@include media-query($xlarge-up) {
			font-size: $value !important;
		}
	}
}

// Font-size mixins
// usage: @include font-size-($font-size-lg1);
@mixin font-size-($value) {
	font-size: $value;
}
