// -----------------------------------------------------------------------------
// This file defines how the colors will be used within the design system.
// -----------------------------------------------------------------------------

// Usage variables (application-focused)
$col--highlight: $col--blue;

// Backgrounds
$col--background-html: $col--gray;
$col--background-site-wrapper: $col--gray;

// Text colors
$col--type-body: $col--white;

// Selected text
$col--selection-bg: $col--highlight;
$col--selection-text: $col--white;

// Link text
$col--link: $col--white;
$col--link-hover: $col--white;
$col--link-active: $col--white;
$col--link-underline: $col--white;
$col--link-underline-hover: rgba($col--white, 0.35);
$col--link-underline-active: transparent;
$col--link-tap-highlight: transparent;

// Video plyr
$col--plyr-play: rgba($col--black, 0.75);
$col--plyr-hover: $col--black;
$col--plyr-progress: rgba($col--black, 0.75);
